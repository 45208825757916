import {mapActions} from "vuex";

export var ProductAutocompleteRequestsMixin = {
    data: () => ({
        productSearch: "",
        timerId: null,
        page: 0,
        perPage: 20,
        productsLoading: false,
    }),
    methods: {
        ...mapActions({
            getProducts: 'products/getProductsBy',
            addProducts: 'products/addProductsBy',
        }),
        getProductsBy (search) {
            this.page = 1;
            this.productsLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getProducts(args).then(() => {
                this.productsLoading = false;
            });
        },
        addProductsBy (search) {
            this.page++;
            this.productsLoading = true;
            let args = {
                per_page: this.perPage,
                page: this.page,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addProducts(args).then(() => {
                this.productsLoading = false;
            });
        },
        loadProducts () {
            this.getProductsBy("");
        },
        productIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addProductsBy(this.productSearch);
            }
        },
        searchProduct (val) {
            if (val) {
                this.page = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getProductsBy(val);
                }, 500);
            }
        },
    },
};
