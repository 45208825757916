<template>
  <div class="client-custom-fields">
    <div class="title-headline"><h4>Доставка</h4></div>
    <div class="client-custom-fields__body">
      <div class="evi-toolbar__night mb-11 mt-2" :class="{'active': isActive}">
        <v-switch
            label="Нужна доставка"
            v-model="isActive"
            :disabled="!canEdit"
            @change="save"
            hide-details
        ></v-switch>
      </div>
      <div v-if="isActive">
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="selectedDeliveryService"
            :items="deliveryService"
            item-text="name"
            item-value="value"
            label="Служба доставки"
            item-color="green"
            :disabled="!canEdit"
            @change="save"
            hide-details
        >
          <template v-slot:append>
          <span class="evi-select__arrow">
              <v-icon>mdi-chevron-down</v-icon>
          </span>
          </template>
        </v-select>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="localCdekTariffs"
            v-model="cdek.cdek_tariff_id"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            @focus="getCdekTariffs"
            label="Тариф"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            @change="save"
        >
          <template v-slot:label>
            Тариф
            <span class="important">*</span>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
        </v-autocomplete>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="selectedDeliveryMethod"
            :items="deliveryMethod"
            item-text="name"
            item-value="value"
            label="Способ доставки"
            item-color="green"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'own'"
        >
          <template v-slot:append>
          <span class="evi-select__arrow">
              <v-icon>mdi-chevron-down</v-icon>
          </span>
          </template>
        </v-select>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="fio"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService !== 'cdek'"
        >
          <template v-slot:label>
            ФИО
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="phone"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService !== 'cdek'"
        >
          <template v-slot:label>
            Мобильный телефон
          </template>
        </v-text-field>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="selectedCountry"
            :items="countries"
            label="Страна"
            item-color="green"
            :disabled="!canEdit"
            @change="save"
            hide-details
        >
          <template v-slot:append>
          <span class="evi-select__arrow">
              <v-icon>mdi-chevron-down</v-icon>
          </span>
          </template>
        </v-select>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_recipient_name"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
        >
          <template v-slot:label>
            ФИО получателя
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_recipient_phone"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
        >
          <template v-slot:label>
            Номер телефона получателя
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_recipient_company"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
        >
          <template v-slot:label>
            Название компании
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_package_weight"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            type="number"
        >
          <template v-slot:label>
            Вес посылки (граммы)
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_package_length"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            type="number"
        >
          <template v-slot:label>
            Длина посылки (см)
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_package_width"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            type="number"
        >
          <template v-slot:label>
            Ширина посылки (см)
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_package_height"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            type="number"
        >
          <template v-slot:label>
            Высота посылки (см)
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-autocomplete
            class="evi-autocomplete mb-5"
            :class="{'margin-none': cdek.cdek_from_city_id}"
            color="#44D370"
            :items="localCdekCities"
            v-model="cdek.cdek_from_city_id"
            item-text="city"
            item-value="id"
            clearable
            item-color="green"
            @focus="getCdekCities"
            label="Откуда (населенный пункт)"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            @change="save"
            :search-input.sync="fromCitySearch"
            @input.native="searchCity(fromCitySearch)"
        >
          <template v-slot:label>
            Откуда (населенный пункт)
            <span class="important">*</span>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="cdek-cities">{{item.city}}, {{ item.sub_region }}, {{item.region}}</div>
          </template>
          <template v-slot:item="{item}">
            <div class="cdek-cities">{{item.city}}, {{ item.sub_region }}, {{item.region}}</div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="localCdekOffices"
            v-model="cdek.cdek_from_office_id"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            @focus="getCdekOffices({city_code: cdek.cdek_to_city.code})"
            label="Откуда (ПВЗ)"
            hide-details
            v-if="showCdekFromPvz"
            @change="save"
        >
          <template v-slot:label>
            Из ПВЗ
            <span class="important">*</span>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_from_address"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="showCdekFromAddress"
            :key="keyCdekFromAddress"
        >
          <template v-slot:label>
            С адреса
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-autocomplete
            class="evi-autocomplete mb-5"
            :class="{'margin-none': cdek.cdek_to_city_id}"
            color="#44D370"
            :items="localCdekCities"
            v-model="cdek.cdek_to_city_id"
            item-text="city"
            item-value="id"
            clearable
            item-color="green"
            @focus="getCdekCities"
            label="Куда (населенный пункт)"
            hide-details
            v-if="selectedDeliveryService === 'cdek'"
            @change="save"
            :search-input.sync="toCitySearch"
            @input.native="searchCity(toCitySearch)"
        >
          <template v-slot:label>
            Куда (населенный пункт)
            <span class="important">*</span>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="cdek-cities">{{item.city}}, {{ item.sub_region }}, {{item.region}}</div>
          </template>
          <template v-slot:item="{item}">
            <div class="cdek-cities">{{item.city}}, {{ item.sub_region }}, {{item.region}}</div>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="localCdekOffices"
            v-model="cdek.cdek_to_office_id"
            item-text="name"
            item-value="id"
            clearable
            item-color="green"
            @focus="getCdekOffices({city_code: cdek.cdek_to_city.code})"
            label="Куда (населенный пункт)"
            hide-details
            v-if="showCdekToPvz"
            @change="save"
        >
          <template v-slot:label>
            В ПВЗ
            <span class="important">*</span>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
        </v-autocomplete>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="cdek.cdek_to_address"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="showCdekToAddress"
            :key="keyCdekFromAddress +1"
        >
          <template v-slot:label>
            На адрес
            <span class="important">*</span>
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="city"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService !== 'cdek'"
        >
          <template v-slot:label>
            Город
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="address"
            :disabled="!canEdit"
            @change="save"
            hide-details
            v-if="selectedDeliveryService !== 'cdek'"
        >
          <template v-slot:label>
            Адрес доставки
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="index"
            :disabled="!canEdit"
            v-if="selectedDeliveryService === 'post'"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Индекс
          </template>
        </v-text-field>
        <div >
          <v-menu
              v-model="sendDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!canEdit"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="sendDateFormated"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  label="Дата отправки"
                  color="#44D370"
                  class="evi-text-field mb-7"
                  hide-details
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="sendDate"
                @input="sendDateMenu = false"
                color="#44D370"
                first-day-of-week="1"
                :disabled="!canEdit"
                @change="save"
            ></v-date-picker>
          </v-menu>
          <v-menu
              v-model="deliveryDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!canEdit"
              v-if="selectedDeliveryService !== 'cdek'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="deliveryDateFormated"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  label="Дата доставки"
                  color="#44D370"
                  class="evi-text-field mb-7"
                  hide-details
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="deliveryDate"
                @input="deliveryDateMenu = false"
                color="#44D370"
                first-day-of-week="1"
                :disabled="!canEdit"
                @change="save"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="item-page__text-field mb-7 mt-4"
             v-if="selectedDeliveryService !== 'cdek'">
          <small>Время доставки</small>
          <div>
            <v-menu
                v-model="deliveryTimeFromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="!canEdit"
                @change="save"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="deliveryTimeFrom"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="#44D370"
                    class="evi-text-field"
                    prefix="с"
                    hide-details
                >
                </v-text-field>
              </template>
              <v-time-picker
                  v-model="deliveryTimeFrom"
                  @input="deliveryTimeFromMenu = false"
                  color="#44D370"
                  format="24hr"
                  @change="save"
              ></v-time-picker>
            </v-menu>
            <v-menu
                v-model="deliveryTimeToMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="!canEdit"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="deliveryTimeTo"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="#44D370"
                    class="evi-text-field"
                    prefix="по"
                    hide-details
                >
                </v-text-field>
              </template>
              <v-time-picker
                  v-model="deliveryTimeTo"
                  @input="deliveryTimeToMenu = false"
                  color="#44D370"
                  format="24hr"
                  @change="save"
              ></v-time-picker>
            </v-menu>
          </div>
        </div>
        <v-textarea
            no-resize
            class="evi-textarea mb-7"
            color="#44D370"
            v-model="courierComment"
            :disabled="!canEdit"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Примечание к доставке
          </template>
        </v-textarea>
        <v-textarea
            no-resize
            class="evi-textarea mb-7"
            color="#44D370"
            v-model="clientComment"
            :disabled="!canEdit"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Примечание от клиента
          </template>
        </v-textarea>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="selectedMailStatus"
            :items="mailStatus"
            label="Статус доставки"
            item-color="green"
            :disabled="!canEdit"
            @change="save"
            hide-details
        >
          <template v-slot:append>
          <span class="evi-select__arrow">
              <v-icon>mdi-chevron-down</v-icon>
          </span>
          </template>
        </v-select>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="track"
            :disabled="!canEdit"
            v-if="selectedDeliveryService === 'post'"
            @change="save"
            hide-details
        >
          <template v-slot:label>
            Трек номер
          </template>
        </v-text-field>
        <v-btn class="evi-button-green evi-button-green--fill mb-5 w-100" @click="sendToCdek(dealId)" v-if="selectedDeliveryService === 'cdek' && !cdek.cdek_tracking_number">
          Отправить заказ в СДЭК
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Delivery",
  props: {
    dealId: {
      type: Number,
    },
    canEdit: {
      type: Boolean,
    },
    delivery: {
      type: Object,
    }
  },
  data: () => ({
    isActive: false,
    sendDateMenu: false,
    deliveryDateMenu: false,
    deliveryTimeFromMenu: false,
    deliveryTimeToMenu: false,
    deliveryService: [
      {
        name: 'Своими силами',
        value: 'own',
      },
      {
        name: 'СДЭК',
        value: 'cdek',
      },
      {
        name: 'Яндекс',
        value: 'yandex',
      },
      {
        name: 'Почта России',
        value: 'post',
      },
    ],
    mailStatus: ['Передано на отправку', 'Отправлено', 'Посылка зависла', 'В месте вручения', 'Выдан', 'Возврат', 'Возврат по ошибке', 'Отказ клиента'],
    deliveryMethod: [
      {
        name: 'Курьер',
        value: 'courier'
      },
      {
        name: 'Самовывоз',
        value: 'pickup'
      },
      {
        name: 'Почта',
        value: 'post'
      },
      {
        name: 'Другое',
        value: 'other'
      },
    ],
    countries: ['Россия',],
    selectedDeliveryService: null,
    selectedDeliveryMethod: null,
    fio: '',
    phone: '',
    selectedCountry: '',
    city: '',
    address: '',
    index: '',
    sendDate: '',
    deliveryDate: '',
    deliveryTimeFrom: '',
    deliveryTimeTo: '',
    courierComment: '',
    clientComment: '',
    track: '',
    selectedMailStatus: '',
    selectedCdekTariff: null,
    selectedCdekCity: null,
    selectedCdekRegion: null,
    cdek: {
      cdek_tariff_id: null,
      cdek_recipient_name: null,
      cdek_recipient_phone: null,
      cdek_recipient_company: null,
      cdek_from_city_id: null,
      cdek_from_address: null,
      cdek_from_office_id: null,
      cdek_to_city_id: null,
      cdek_to_address: null,
      cdek_to_office_id: null,
      cdek_tracking_number: null,
      cdek_package_weight: 0,
      cdek_package_length: 0,
      cdek_package_width: 0,
      cdek_package_height: 0,
    },
    timerId: null,
    fromCitySearch: '',
    toCitySearch: '',
    keyCdekFromAddress: 0,
  }),
  computed: {
    ...mapState('deals', ['cdekTariffs','cdekCities', 'cdekOffices']),
    sendDateFormated() {
      return this.sendDate ? this.$moment(this.sendDate).format('DD.MM.YYYY') : '';
    },
    deliveryDateFormated() {
      return this.deliveryDate ? this.$moment(this.deliveryDate).format('DD.MM.YYYY') : '';
    },
    localCdekCities () {
      let array = [...this.cdekCities];
      if (this.cdek.cdek_from_city) {
        array.push(this.cdek.cdek_from_city);
      }
      if (this.cdek.cdek_to_city && this.cdek.cdek_from_city_id !== this.cdek.cdek_to_city_id) {
        array.push(this.cdek.cdek_to_city);
      }
      return array;
    },
    localCdekOffices () {
      let array = [...this.cdekOffices];
      if (this.cdek.cdek_from_office) {
        array.push(this.cdek.cdek_from_office);
      }
      if (this.cdek.cdek_to_office && this.cdek.cdek_from_office_id !== this.cdek.cdek_to_office_id) {
        array.push(this.cdek.cdek_to_office);
      }
      return array;
    },
    localCdekTariffs () {
      if (this.cdek.cdek_tariff) {
        return [this.cdek.cdek_tariff, ...this.cdekTariffs];
      }
      return [...this.cdekTariffs];
    },
    showCdekFromAddress () {
      return this.selectedDeliveryService === 'cdek' &&
          this.selectedCdekTariff?.from_method === 'door';
    },
    showCdekToAddress () {
      return this.selectedDeliveryService === 'cdek' &&
          this.selectedCdekTariff?.to_method === 'door';
    },
    showCdekFromPvz () {
      return this.selectedDeliveryService === 'cdek' &&
          this.selectedCdekTariff?.from_method === 'pvz' ||
          this.selectedCdekTariff?.from_method === 'postamat' ;
    },
    showCdekToPvz () {
      return this.selectedDeliveryService === 'cdek' &&
          this.selectedCdekTariff?.to_method === 'pvz' ||
          this.selectedCdekTariff?.to_method === 'postamat' ;
    },
  },
  methods: {
    ...mapActions({
      editDealDelivery: 'deals/editDealDelivery',
      getCdekTariffs: 'deals/getCdekTariffs',
      getCdekCities: 'deals/getCdekCities',
      getCdekOffices: 'deals/getCdekOffices',
      sendToCdek: 'deals/sendToCdek',
    }),
    searchCity (val) {
      if (val && val.length >= 3) {
        if (this.timerId)
          clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.getCdekCities({city: val});
        }, 500);
      }
    },
    save () {
      let payload = {
        is_active: this.isActive,
        type: this.selectedDeliveryService,
        method: this.selectedDeliveryMethod,
        full_name: this.fio,
        mobile_phone: this.phone,
        postal_code: this.index,
        city: this.city,
        address: this.address,
        send_date: this.sendDate,
        pickup_date: this.deliveryDate,
        client_comment: this.clientComment,
        internal_comment: this.courierComment,
        tracking_number: this.track,
        postal_status: this.selectedMailStatus,
        country: this.selectedCountry,
        pickup_time_from: this.deliveryTimeFrom,
        pickup_time_to: this.deliveryTimeTo,
      };

      this.editDealDelivery({id: this.dealId, main: payload, cdek: this.cdek});
    },
    initiateDelivery (val) {
      if (val) {
        this.isActive = val.is_active;
        this.selectedDeliveryService = val.type;
        this.selectedDeliveryMethod = val.method;
        this.fio = val.full_name;
        this.phone = val.mobile_phone;
        this.index = val.postal_code;
        this.city = val.city;
        this.address = val.address;
        this.sendDate = val.send_date;
        this.deliveryDate = val.pickup_date;
        this.clientComment = val.client_comment;
        this.courierComment = val.internal_comment;
        this.track = val.tracking_number;
        this.selectedMailStatus = val.postal_status;
        this.selectedCountry = val.country;
        this.deliveryTimeFrom = val.pickup_time_from;
        this.deliveryTimeTo = val.pickup_time_to;

        if (val.cdek) {
          for (const key in val.cdek) {
            this.cdek[key] = val.cdek[key];
            if (key === 'cdek_tariff') {
              this.selectedCdekTariff = val.cdek[key];
              this.keyCdekFromAddress++;
            }
          }
        }
      }
    }
  },
  mounted() {
    this.initiateDelivery(this.delivery);
  },
  watch: {
    delivery (val) {
      this.initiateDelivery(val);
    },
    // 'cdek': {
    //   handler (val) {
    //     this.selectedCdekTariff = val.cdek_tariff;
    //     console.warn(this.selectedCdekTariff.name)
    //     console.warn(this.selectedCdekTariff.from_method)
    //     console.warn(this.selectedCdekTariff.to_method)
    //     this.keyCdekFromAddress++;
    //   }, deep: true, immediate: true
    // }
  }
}
</script>

<style lang="scss" scoped>
.margin-none {
  margin-bottom: 0 !important;
}
</style>
