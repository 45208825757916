<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">{{ editMode ? 'Редактирование продукта в корзине' : 'Добавление продукта в корзину' }}</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              :items="products"
              v-model="product"
              item-text="name"
              item-value="id"
              :rules="productRules"
              :loading="productsLoading"
              @focus="loadProducts"
              :search-input.sync="productSearch"
              @input.native="searchProduct(productSearch)"
              clearable
              item-color="green"
              return-object
          >
            <template v-slot:label>
              Продукт
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="productIntersect"/>
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              v-model="selectedUser"
              :items="localUsers"
              item-text="name"
              item-value="id"
              placeholder="Выберите ответственного сотрудника"
              :rules="userRules"
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              cache-items
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Выберите ответственного сотрудника
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete mb-11"
              color="#44D370"
              v-if="product && product.sizes.length"
              :items="product.sizes"
              v-model="productSize"
              item-text="value"
              item-value="id"
              clearable
              item-color="green"
              return-object
          >
            <template v-slot:label>
              Размер
            </template>
            <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
            </template>
          </v-autocomplete>
          <v-text-field
              class="evi-text-field mb-11"
              color="#44D370"
              v-model="count"
              :rules="countRules"
              type="number"
          >
            <template v-slot:label>
              Количество
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-11"
              color="#44D370"
              v-model="price"
              placeholder="Стоимость продукта"
              v-mask="currencyMask"
              v-if="product"
          >
            <template v-slot:label>
              Стоимость за единицу
            </template>
          </v-text-field>
          <div class="current-price" v-if="product">
            Текущая стоимость: <span>{{currentPrice | VMask(currencyMask)}}</span>
          </div>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions" v-if="!editMode">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Добавить продукт
        </v-btn>
        <v-btn class="evi-button-green ml-0" @click="createAnother">
          Добавить еще
        </v-btn>
      </v-card-actions>
      <v-card-actions class="modal-create__actions" v-else>
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {ProductAutocompleteRequestsMixin} from "@/mixins/autocomplete/ProductAutocompleteRequestsMixin";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "AddProduct",
  mixins: [ProductAutocompleteRequestsMixin, UserAutocompleteRequestsMixin ],
  props: {
    dealId: {
      type: Number,
    },
    editedProduct: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    dealProducts: {
      type: Array,
    }
  },
  data: () => ({
    currencyMask,
    valid: true,
    product: null,
    count: 1,
    productRules: [(v) => !!v || 'Выберите продукт'],
    countRules: [(v) => !!v || 'Укажите количество'],
    price: null,
    productSize: {},
    selectedUser: null,
    userRules: [(v) => !!v || 'Выберите ответственного сотрудника'],
  }),
  computed: {
    ...mapState("products", ["products",]),
    ...mapState("users", ["users",]),
    ...mapState("user", ["profile",]),
    currentPrice () {
      if (this.editMode) {
        return this.editedProduct.deal_price;
      }
      return this.productSize.price || this.product.price;
    },
    priceNum () {
      if (this.editMode) {
        return parseFloat(this.editedProduct.deal_price);
      }
      if (this.price) {
        let priceArray = this.price.split(" ");

        let sum = '';

        for(let i = 0; i < priceArray.length; i++){
          sum += priceArray[i];
        }

        return parseFloat(sum);
      }
      else {
        return 0;
      }
    },
    localUsers() {
      let array = [];
      if (this.editedProduct && this.editedProduct.deal_product_user) {
        array.push(this.editedProduct.deal_product_user);
      }
      array =  [...this.users, this.profile, ...array];
      return array;
    },
    newProduct () {
      let payload = {
        dealId: this.dealId,
        data: {
          product_id: this.product.id,
          count: this.count,
          user_id: this.selectedUser,
          price: this.priceNum || (this.productSize.price || this.product.price),
        },
      };
      if (this.productSize.id) {
        payload.data.product_size_id = this.productSize.id;
      }
      if (this.editMode) {
        payload.data.rel_id = this.editedProduct.rel_id;
      }

      return payload;
    },
  },
  methods: {
    ...mapActions({
      addProduct: 'deals/addProduct',
      editProduct: 'deals/editProduct',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        this.addProduct(this.newProduct).then(() => {
          this.closeDialog();
        });
      }
    },
    save () {
      if (this.validate()) {
        this.editProduct(this.newProduct).then(() => {
          this.closeDialog();
        });
      }
    },
    createAnother() {
      if (this.validate()) {
        this.addProduct(this.newProduct).then(() => {
          this.$emit('getData');
          this.reloadData();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$emit('editModeOff');
      this.reloadData();
    },
    reloadData () {
      this.product = null;
      this.count = 1;
      this.price = null;
      this.selectedUser = this.profile.id;
      this.productSize = {};
      this.$refs.form.resetValidation();
    },
    initEditMode (val) {
      if (val.length && this.editMode) {
        this.product = val.find(i => i.id === this.editedProduct.id);
        if (this.editedProduct.deal_product_size) this.productSize = this.editedProduct.deal_product_size;
        this.selectedUser = this.editedProduct.deal_product_user.id;
        this.count = this.editedProduct.count;
      }
    }
  },
  beforeDestroy() {
    this.reloadData();
    this.$emit('editModeOff');
  },
  mounted() {
    this.selectedUser = this.profile.id;
    if (!this.products.length) this.loadProducts();
    this.initEditMode(this.products);
  },
  watch: {
    products: {
      handler: function (val) {
        this.initEditMode(val);
      },
      deep: true,
    },
    editedProduct () {
      this.initEditMode(this.products);
    },
  }
}
</script>

<style scoped>
.modal-create__actions {
  flex-direction: column;
}
</style>
